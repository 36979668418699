html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  min-height: -webkit-fill-available;
}

body {
  position: fixed;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.refresh-view{
  height: 100%;
}
.ptr-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #aaa;
  z-index: 10;
  text-align: center;
  height: 50px;
  transition: all;
  padding-top: 15px;
}

.ptr-element svg:first-child {
  opacity: 0.6;
  font-size: 34px;
  transition: all 0.25s ease;
  margin-top: 5px;
}
.ptr-element svg:nth-child(2) {display: none}
.ptr-loading .ptr-element svg:nth-child(2) {display: inline-block}
.ptr-refresh .ptr-element svg:first-child {
  transform: rotate(180deg);
}
.ptr-loading .ptr-element svg:first-child,
.ptr-reset .ptr-element svg:first-child {
  display: none;
}

.ptr-element svg:nth-child(2) {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0);
  }
}

.ptr-loading .refresh-view,
.ptr-reset .refresh-view,
.ptr-loading .ptr-element,
.ptr-reset .ptr-element {
  transition: all 0.25s ease;
}

.ptr-reset .refresh-view {
  transform: translate3d(0, 0, 0);
}

.ptr-loading .refresh-view {
  transform: translate3d(0, 30px, 0);
}


body:not(.ptr-loading) .ptr-element {
  transform: translate3d(0, -50px, 0);
}